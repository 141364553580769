/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'ws_inter_var', sans-serif; }
  }
*/

@font-face {
  font-family: 'ws_inter_var';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url("../../fonts/inter/webfonts/Inter-roman.var.woff2?v=3.18") format("woff2");
}

@font-face {
  font-family: 'ws_inter_var';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url("../../fonts/inter/webfonts/Inter-italic.var.woff2?v=3.18") format("woff2");
}
